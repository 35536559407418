.verify-body {
	padding: 1em 0;
}

.resend {
	margin-right: 1em;
}

.button-container {
	display: flex;
}