.filters-container {
    display: flex;
    gap: 30px;
    align-items: flex-end;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

.header-container > * {
    max-width: 60%;
}
