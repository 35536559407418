.filters-container {
    display: flex;
    gap: 30px;
    align-items: flex-end;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

.header-container > * {
    max-width: 60%;
}

.popover-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
}

.center-column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
