.tiles {
	display: flex;
	flex-direction: column;
	gap: var(--size-400);
	padding-bottom: var(--size-500)
}

.tile {
	width: 100%;
	display: flex;
	color: inherit;
}

.tile-img {
	width: 8rem;
	height: 100%;
}

.tile-body {
	display: flex;
	flex-direction: column;
	padding: var(--size-400);
	gap: var(--size-300);
	width: calc(100% - var(--size-600));
}

.tile-chevron {
	display: flex;
	align-items: center;
}

.tile-content {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: var(--size-200);
}

.application {
	display: flex;
	flex-direction: column;
	gap: var(--size-400);
}

.filter-container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row-reverse;
	gap: var(--size-400);
}

.verify-body {
	padding: 1em 0;
}

@media (min-width: 48rem) {
	.tiles {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.tile {
		flex-direction: column;
		width: calc((100% / 2) - var(--size-200));
	}

	.tile-img {
		width: 100%;
		height: auto;
	}
}

.loading-spinner {
	margin-top: 10%;
	margin-left: 50%;
}

.error-note {
	margin-bottom: 10px;
}