.main {
	padding: var(--size-400) var(--size-500);
}

@media (min-width: 64em) {
	.main {
		width: 56em;
		margin-left: auto;
		margin-right: auto;
	}
}

.detailed-info {
	margin-top: var(--size-400);
}

.detailed-info-details {
	overflow: auto;
	max-height: 24rem;
}

.content {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.footer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	margin-top: auto;

}

.footer-text {
	color: #525F6C;
}

a,
a:visited {
	color: #0065bd;
	text-decoration: none;
}

a:hover,
a:focus{
	text-decoration: underline;
}

.footer ul {
	text-align: center;
}

.footer ul li {
	display: inline-block;
	margin: 0 4px;
}

.footer ul li:not(:first-of-type) {
	margin-left: 0;
}
