.block-container > * {
    display: block;
    margin-bottom: 10px;
}

.label-input-container > * {
    display: block;
}

.button-container {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    margin-top: 20px;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

.loading-container {
	width: 100%;
	cursor: wait;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 50px 0px;
}

.icon-block-container {
    width: 100%;
    display: block;
    margin: 50px 0px;
}

.centered-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-content > * {
    display: block;
    margin-bottom: 10px;
    max-width: 42%;
}

.readonly-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #666;
    cursor: not-allowed;
}

.back-button {
    margin-left: auto;
}
